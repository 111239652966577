import axios from "axios"
import React, { useState, useEffect } from "react"
import { Parallax } from "react-scroll-parallax"
import { StaticQuery, graphql } from "gatsby"
import Link from "../../utils/transitionLinks/LinkFade"
import Img from "gatsby-image"
import { StaticGoogleMap, Marker } from "react-static-google-map"
import { Container, Row } from "reactstrap"
import SecLines from "../sec-lines/sec-lines"
import Recaptcha from "react-recaptcha"
import { Button, Label, FormGroup } from "reactstrap"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

import {
  AvForm,
  AvInput,
  AvField,
  AvRadioGroup,
  AvRadio,
  AvGroup,
} from "availity-reactstrap-validation"

import "./contact.css"
const API_CONTACT_URL = "/api/contact-form"

const RenderModal = props => {
  return (
    <div>
      <Modal isOpen={props.modal} toggle={props.hideModal}>
        <ModalHeader toggle={props.hideModal}>{props.title}</ModalHeader>
        <ModalBody>{props.modalText}</ModalBody>
        <ModalFooter>
          <Button
            className="btn blog-btn flat-btn"
            color="secondary"
            onClick={props.hideModal}
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
const Contact = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          backgroundImage: file(relativePath: { eq: "all/contact.jpg" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => {
        const [state, setState] = useState({ errors: [] })
        const [captchaSolved, setCaptcha] = useState(false)
        const [modal, setModal] = useState(false)
        const showModal = () => setModal(true)
        const hideModal = () => setModal(false)

        const reCaptchaCallback = () => {
          return
        }

        const reCaptchaVerifyCallback = response => {
          if (response) {
            setCaptcha(true)
          }
        }

        const handleSubmit = (event, errors, values) => {
          event.persist()

          setState({ values: values, errors: errors })
        }

        const submitForm = () => {
          axios
            .get(API_CONTACT_URL, {
              params: { ...state.values },
            })
            .then(res => {
              state.response = (
                <p dangerouslySetInnerHTML={{ __html: res.data.body }}></p>
              )
              showModal()
            })
            .catch(err => {
              console.log("contact error:")
              console.error(err)
              console.log("state.errors", state.errors)
              showModal()
            })
        }
        useEffect(() => {
          if (!captchaSolved) {
          } else if (state.errors.length === 0 && captchaSolved) {
            submitForm()
          } else {
          }
        }, [state])

        const backgroundImage = data.backgroundImage.childImageSharp.fluid

        return (
          <div className="content">
            {state.errors.length > 0 ? (
              <RenderModal
                title="Fehler beim übertragen!"
                modalText={`Irgendwas ist schief gelaufen! <br />${state.errors}`}
                modal={modal}
                hideModal={hideModal}
              ></RenderModal>
            ) : (
              <RenderModal
                title="Ihre Nachricht wurde erfolgreich versendet"
                modalText={state.response}
                modal={modal}
                hideModal={hideModal}
                text=""
              ></RenderModal>
            )}

            <div className="single-page-decor"></div>
            <div className="single-page-fixed-row">
              <div className="scroll-down-wrap">
                <div className="mousey">
                  <div className="scroller"></div>
                </div>
                <span>Scroll Down</span>
              </div>
              <Link to="/" className="single-page-fixed-row-link">
                <i className="fal fa-arrow-left"></i> <span>Back to home</span>
              </Link>
            </div>
            <section className="parallax-section dark-bg sec-half parallax-sec-half-right">
              <Parallax className="bg par-elem" y={["-10px", "300px"]}>
                <div className="overlay"></div>
                <Img
                  alt="Background"
                  className="imgbg"
                  fluid={backgroundImage}
                />
              </Parallax>

              <div className="pattern-bg"></div>
              <div className="container">
                <div className="section-title">
                  <h2>
                    <span>Kontaktieren </span> Sie mich
                  </h2>
                  <p>Am Anfang war das Wort. Und danach folgte eine Antwort.</p>
                  <div className="horizonral-subtitle">
                    <span>Kontakt</span>
                  </div>
                </div>
              </div>
              <Link
                to="#sec1"
                className="custom-scroll-link hero-start-link"
                onClick={() => {
                  scrollTo("#sec1")
                }}
              >
                <span>Los geht's</span>
                <i className="fal fa-long-arrow-down"></i>
              </Link>
            </section>
            <section data-scrollax-parent="true" id="sec1">
              <div
                className="section-subtitle"
                data-scrollax="properties: { translateY: '-250px' }"
              >
                Kontakt<span>//</span>
              </div>
              <Container>
                <div className="fl-wrap contact-details mar-bottom">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="pr-title fl-wrap">
                        <h3>Kontakt Details</h3>
                        <span>
                          Am Anfang war das Wort. Und danach folgt eine Antwort.
                        </span>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="features-box-container single-serv fl-wrap">
                        <Row className="contact">
                          <div className="features-box col-md-4 col-sm-12">
                            <div className="time-line-icon">
                              <i className="fal fa-mobile-android"></i>
                            </div>
                            <h3>01. Telefon</h3>
                            <a href="tel:+436505550750">
                              +43 / (0) 650 / 555 0750
                            </a>
                          </div>

                          <div className="features-box col-md-4 col-sm-12">
                            <div className="time-line-icon">
                              <i className="fal fa-compass"></i>
                            </div>
                            <h3>02. Ort</h3>
                            <Link to="#">Vienna, Austria</Link>
                          </div>

                          <div className="features-box col-md-4 col-sm-12">
                            <div className="time-line-icon">
                              <i className="fal fa-envelope-open"></i>
                            </div>
                            <h3>03. Email</h3>

                            <Link
                              to="#mailgo"
                              data-address="mail"
                              data-domain="jezekjan.com"
                            >
                              Schreiben Sie mir eine Email (click)
                            </Link>
                          </div>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fw-map-container fl-wrap mar-bottom">
                  <div className="map-container"></div>
                </div>

                <div className="fl-wrap mar-top">
                  <Row>
                    <div className="col-md-3">
                      <div className="pr-title fl-wrap">
                        <h3>Kontaktieren</h3>
                        <span>
                          Lassen Sie mich wissen, wie ich Ihnen helfen kann.
                          Zögern Sie nicht, extravagante Fragen beantworte ich
                          besonders gerne.
                        </span>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div id="contact-form">
                        <AvForm
                          className="custom-form"
                          name="contactform"
                          id="contactform"
                          onSubmit={handleSubmit}
                        >
                          <fieldset>
                            <Row>
                              <div className="col-md-6">
                                <Label>
                                  <i className="fal fa-user"></i>
                                </Label>
                                <AvField
                                  id="name"
                                  name="NAME"
                                  label="Ihr Name *"
                                  type="text"
                                  errorMessage="First and last Name must be given"
                                  validate={{
                                    required: { value: true },
                                    minLength: { value: 6 },
                                    maxLength: { value: 32 },
                                  }}
                                />
                              </div>
                              <div className="col-md-6">
                                <label>
                                  <i className="fal fa-envelope"></i>
                                </label>
                                <AvField
                                  id="email"
                                  name="EMAIL"
                                  label="Email *"
                                  type="email"
                                  required
                                />
                              </div>
                              <div className="col-md-6">
                                <label>
                                  <i className="fal fa-mobile-android"></i>
                                </label>
                                <AvField
                                  id="phone"
                                  name="PHONE"
                                  label="Telefon"
                                  type="text"
                                  validate={{
                                    required: { value: false },
                                    pattern: { value: "^[+0-9]+$" },
                                    minLength: { value: 6 },
                                    maxLength: { value: 32 },
                                  }}
                                />
                              </div>
                              <div className="col-md-6">
                                <AvRadioGroup
                                  name="SUBJECT"
                                  className="subject"
                                  label="Betreff"
                                  required
                                >
                                  <AvRadio
                                    customInput
                                    label="Supportanfrage"
                                    value="Supportanfrage!"
                                  />
                                  <AvRadio
                                    customInput
                                    label="Projektanfrage"
                                    value="Projektanfrage!"
                                  />
                                  <AvRadio
                                    customInput
                                    label="Anfrage"
                                    value="Anfrage!"
                                  />
                                </AvRadioGroup>
                              </div>
                            </Row>

                            <AvField
                              name="MESSAGE"
                              className="message"
                              id="message"
                              label="Ihre Nachricht:"
                              type="textarea"
                              required
                              cols="40"
                              rows="3"
                            />

                            <div className="verify-wrap" id="recaptcha">
                              <Recaptcha
                                sitekey={
                                  process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY
                                }
                                onloadCallback={reCaptchaCallback}
                                verifyCallback={reCaptchaVerifyCallback}
                              />
                              <span className="verify-text">
                                Bitte klicken Sie oben auf die Capatcha, um zu
                                verifizieren, dass Sie ein Mensch sind.
                              </span>
                            </div>
                            <div className="clearfix"></div>

                            <AvGroup check>
                              <Label check>
                                <AvInput
                                  type="checkbox"
                                  trueValue="Yes"
                                  falseValue="No"
                                  required
                                  name="Agreed TOC"
                                />
                                AGBs zustimmen
                              </Label>
                            </AvGroup>
                            <FormGroup>
                              <Button
                                className="btn float-btn flat-btn color-btn"
                                id="submit"
                              >
                                Nachricht senden
                              </Button>
                            </FormGroup>
                          </fieldset>
                        </AvForm>
                      </div>
                    </div>
                  </Row>
                </div>
              </Container>
              <SecLines />
            </section>
          </div>
        )
      }}
    ></StaticQuery>
  )
}

export default Contact
