import React from "react"

const SecLines = () => {
  return (
    <div className="sec-lines">
      <div className="container full-height">
        <div className="line-item"></div>
        <div className="line-item"></div>
        <div className="line-item"></div>
        <div className="line-item"></div>
        <div className="line-item"></div>
      </div>
    </div>
  )
}

export default SecLines
