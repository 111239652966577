import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faTimes } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"

const GetInTouchCta = () => {
  return (
    <Link to="/kontakt" className="contact-btn color-bg">
      <FontAwesomeIcon icon={faEnvelope} />
      <span>Kontaktieren</span>
    </Link>
  )
}

export default GetInTouchCta
