import React from "react"
import ContactPage from "../components/contact/contact"
import GetInTouchCta from "../components/get-in-touch-cta/get-in-touch-cta"
const Contact = () => {
  return (
    <div id="wrapper" className="single-page-wrap">
      <ContactPage />
      <GetInTouchCta />
    </div>
  )
}

export default Contact
